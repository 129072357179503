@use "sass:math";

$black: #000000;
$background: #9eb2b1;
$white: #FFFFFF;
$highlight: #01224d; //#E3CC89
$backgroundHighlight: #fee300; // #f5f5f5;
$cta: #01224d;
$sale: #a51915;
$error: #a51915;
$gradientList: #F6CB62, #EFAA5C, #EC9857, #EA8754, #E4664D, #E2544A, #C9354A, #A91C49, #8D1B48, #6F1947, #511746, #361645;

@function gradientColor($pos: 1) {
	@return nth($gradientList, $pos);
}

$font: Helvetica, Helvetica Neue, Arial, sans-serif;
$headline: Anko-SemiBold, Helvetica, Helvetica Neue, Arial, sans-serif;

$totalGridWidthPx: (100 * 12) + (10 * 11);
$gridColumns: 12;
$gut: 10px;
$gut600: 10px;
$col: math.div(100, 12);
$innerWidth: 98%;

$pageWidth: $totalGridWidthPx * 1px;

@mixin resp($media) {
	@if $media == maxProduct {
		@media only screen and (max-width: 396px) { @content; }
	} @else if $media == max600 {
		@media only screen and (max-width: 599px) { @content; }
	} @else if $media == min600 {
		@media only screen and (min-width: 600px) { @content; }
	} @else if $media == navigation {
		@media only screen and (max-height: 625px) and (min-width: 600px) { @content; }
	} @else if $media == span600900 {
		@media only screen and (min-width: 600px) and (max-width: 899px) { @content; }
	} @else if $media == max750 {
		@media only screen and (max-width: 749px) { @content; }
	} @else if $media == min750 {
		@media only screen and (min-width: 750px) { @content; }
	} @else if $media == max900 {
		@media only screen and (max-width: 899px) { @content; }
	} @else if $media == min900 {
		@media only screen and (min-width: 900px) { @content; }
	} @else if $media == min1000 {
		@media only screen and (min-width: 1000px) { @content; }
	} @else if $media == max1200 {
		@media only screen and (max-width: 1159px) { @content; }
	} @else if $media == min1200 {
		@media only screen and (min-width: 1200px) { @content; }
	}
}

@mixin chevronDown($pos: after) { 
	@if $pos == before {
		&:before { content: ""; width: 8px; height: 8px; border-bottom: 1px solid; border-right: 1px solid; transform: rotateZ(45deg); margin: 6px 0 0 10px; display: inline-block; vertical-align: top; }
	} @else {
		&:after { content: ""; width: 8px; height: 8px; border-bottom: 1px solid; border-right: 1px solid; transform: rotateZ(45deg); margin: 6px 0 0 10px; display: inline-block; vertical-align: top; }
	}
}

@mixin grid($total, $col, $gut, $colColor, $gutColor) {
	background-image: repeating-linear-gradient(
		90deg,
		$gutColor,
		$gutColor $gut,
		$colColor $gut,
		$colColor #{math.div(100%, $gridColumns)}
	);
	@include resp(min600) {
		background-image: repeating-linear-gradient(
			90deg,
			$gutColor,
			$gutColor $gut600,
			$colColor $gut600,
			$colColor #{math.div(100%, $gridColumns)}
		);
	}
}

@function strip-unit($num) {
	@return $num / ($num * 0 + 1);
}

@function w($num, $resp: 0, $multiple: 1) {
	$percent: math.div(100, 12) * $num * 1%;
	$less: 12 * $multiple;

	@return calc(#{$percent} - #{$less}px);
}

@mixin pageWidth() { width: $pageWidth; margin: 0 auto; max-width: calc(100% - #{$gut * 2});
	@include resp(min600) { max-width: calc(100% - #{$gut600 * 2}); }
}

@mixin pageWidthGrid() { width: $pageWidth + $gut; position: relative; left: math.div(-$gut, 2); margin: 0 auto; max-width: calc(100% - #{$gut});
	@include resp(min600) { width: $pageWidth + $gut600; left: -$gut600 / 2; max-width: calc(100% - #{$gut600}); }
}

@mixin headline() { font-family: $headline; line-height: 1em; font-size: 13vw; text-transform: lowercase;
	@include resp(min600) { font-size: 6.5vw; line-height: 1em; }
}

@mixin body() { font-family: $font; font-size: 13px; line-height: 18px; letter-spacing: 0.093em }

@keyframes fadeInOut {
	0% { opacity: 1; }
	20% { opacity: 1; }
	25% { opacity: 0; }
	70% { opacity: 0; }
	75% { opacity: 1; }
	100% { opacity: 1; }
}

.media { display: block; border-bottom: none; }

.mediaCaption { font-family: $headline; padding: 10px 20px; position: relative; opacity: 1; transition: opacity 0.2s ease-in-out;
	h1, h2, h3, h4, h5, h6 { margin: 0; position: relative; display: inline-block;
		svg { position: absolute; left: -5%; top: -10%; width: 110%; height: 100%; z-index: -1; }
	}
	&_link { border-bottom-color: inherit; position: relative; display: block;
		& + & { margin-left: 20px; }
	}
	&__left { text-align: left; }
	&__center { text-align: center; }
	&__right { text-align: right; }
	span.mediaCaption_link { border-bottom: 1px solid; 
		@include resp(min600) { border-bottom: 2px solid; }
	}
	@include resp(min1000) {
		&__double &_link { position: absolute; left: 40px; top: 50%; transform: translateY(-50%); }
		&__double &_link + &_link { left: auto; right: 40px; }
	}
}

.mediaInner { overflow: hidden; position: relative;
	&_image { width: 100%; display: block; position: relative; z-index: 1; opacity: 1; transition: opacity 0.2s ease-in-out; }
	&_video { width: 100%; display: block; position: relative; z-index: 1; }
	a + &_embed, img + &_embed { position: absolute; top: 0; left: 0; }
	&_embed { position: relative; width: 100%; display: block; padding-bottom: 56%;
		iframe { border: none; position: absolute; top: 0; left: 0; width: 100%; height: 100%; cursor: pointer; pointer-events: none; }
		~ div { pointer-events: none; }
	}
	@include resp(max600) {
		&__crop { height: 100vw; width: calc(100vw - 40px); position: relative; overflow: hidden;
			img, video { height: 100%; width: 100%; object-fit: cover; transform: translate(-50%, -50%); position: absolute; left: 50%; top: 50%; display: block; }
			:global(.u_width__full) & { height: 100vw; width: 100%; }
		}
		:global(.u_doubleImage .u_column) & { position: static; }
		:global(.u_doubleImage .u_column:last-child) &_image { position: absolute; top: 0; animation: fadeInOut 4s infinite linear; }
	}
	&_graphicOverlay { position: absolute; z-index: 2;
		svg { width: 100%; height: auto; }
	}
	.mediaCaption { position: absolute; padding: 10px; z-index: 10; max-width: 80%; box-sizing: border-box; background-color: transparent;
		&_inner { display: inline-block; padding: 15px 15px 10px; box-sizing: border-box; }
		&_link { margin: 15px 10px 5px; display: inline-block; }
		&__left.mediaCaption__top { left: 0; top: 4%; text-align: left; }
		&__left.mediaCaption__middle { left: 0; top: 50%; transform: translateY(-50%); text-align: left; }
		&__left.mediaCaption__bottom { left: 0; bottom: 4%; text-align: left; }
		&__center.mediaCaption__top { left: 50%; top: 4%; transform: translateX(-50%); }
		&__center.mediaCaption__middle { left: 50%; top: 50%; transform: translate(-50%, -50%); }
		&__center.mediaCaption__bottom { left: 50%; bottom: 4%; transform: translateX(-50%); }
		&__right.mediaCaption__top { right: 0; top: 4%; text-align: right; }
		&__right.mediaCaption__middle { right: 0; top: 50%; transform: translateY(-50%); text-align: right; }
		&__right.mediaCaption__bottom { right: 0; bottom: 4%; text-align: right; }
		&__transparentBackground { border: none; box-shadow: none; }
		&__double {
			.mediaCaption_link { position: static; }
		}
		@include resp(max600) {
			&_inner { padding: 5px 10px; }
		}
	}
	&__spanText { position: static; }
	&__logo { padding: 20%; }
	&__logo &_image { display: inline-block; vertical-align: middle; }
	@include resp(min600) { height: 100vh; 
		&__logo { padding: 0; }
		&__logo:before { content: ""; width: 0; height: 100vh; display: inline-block; vertical-align: middle; }
		&_image__logo { width: w(6); margin: 0 auto; }
	}
}

:global(.is_active) {
	.mediaInner_image { opacity: 0; }
	~ div:not(.mediaInner_embed) { opacity: 0; }
}

@supports(object-fit: cover) {
	.mediaInner_image:not(.mediaInner_image__logo) { height: 100vh; object-fit: cover; object-position: center; }
}

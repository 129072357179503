@use "sass:math";

$black: #000000;
$background: #9eb2b1;
$white: #FFFFFF;
$highlight: #01224d; //#E3CC89
$backgroundHighlight: #fee300; // #f5f5f5;
$cta: #01224d;
$sale: #a51915;
$error: #a51915;
$gradientList: #F6CB62, #EFAA5C, #EC9857, #EA8754, #E4664D, #E2544A, #C9354A, #A91C49, #8D1B48, #6F1947, #511746, #361645;

@function gradientColor($pos: 1) {
	@return nth($gradientList, $pos);
}

$font: Helvetica, Helvetica Neue, Arial, sans-serif;
$headline: Anko-SemiBold, Helvetica, Helvetica Neue, Arial, sans-serif;

$totalGridWidthPx: (100 * 12) + (10 * 11);
$gridColumns: 12;
$gut: 10px;
$gut600: 10px;
$col: math.div(100, 12);
$innerWidth: 98%;

$pageWidth: $totalGridWidthPx * 1px;

@mixin resp($media) {
	@if $media == maxProduct {
		@media only screen and (max-width: 396px) { @content; }
	} @else if $media == max600 {
		@media only screen and (max-width: 599px) { @content; }
	} @else if $media == min600 {
		@media only screen and (min-width: 600px) { @content; }
	} @else if $media == navigation {
		@media only screen and (max-height: 625px) and (min-width: 600px) { @content; }
	} @else if $media == span600900 {
		@media only screen and (min-width: 600px) and (max-width: 899px) { @content; }
	} @else if $media == max750 {
		@media only screen and (max-width: 749px) { @content; }
	} @else if $media == min750 {
		@media only screen and (min-width: 750px) { @content; }
	} @else if $media == max900 {
		@media only screen and (max-width: 899px) { @content; }
	} @else if $media == min900 {
		@media only screen and (min-width: 900px) { @content; }
	} @else if $media == min1000 {
		@media only screen and (min-width: 1000px) { @content; }
	} @else if $media == max1200 {
		@media only screen and (max-width: 1159px) { @content; }
	} @else if $media == min1200 {
		@media only screen and (min-width: 1200px) { @content; }
	}
}

@mixin chevronDown($pos: after) { 
	@if $pos == before {
		&:before { content: ""; width: 8px; height: 8px; border-bottom: 1px solid; border-right: 1px solid; transform: rotateZ(45deg); margin: 6px 0 0 10px; display: inline-block; vertical-align: top; }
	} @else {
		&:after { content: ""; width: 8px; height: 8px; border-bottom: 1px solid; border-right: 1px solid; transform: rotateZ(45deg); margin: 6px 0 0 10px; display: inline-block; vertical-align: top; }
	}
}

@mixin grid($total, $col, $gut, $colColor, $gutColor) {
	background-image: repeating-linear-gradient(
		90deg,
		$gutColor,
		$gutColor $gut,
		$colColor $gut,
		$colColor #{math.div(100%, $gridColumns)}
	);
	@include resp(min600) {
		background-image: repeating-linear-gradient(
			90deg,
			$gutColor,
			$gutColor $gut600,
			$colColor $gut600,
			$colColor #{math.div(100%, $gridColumns)}
		);
	}
}

@function strip-unit($num) {
	@return $num / ($num * 0 + 1);
}

@function w($num, $resp: 0, $multiple: 1) {
	$percent: math.div(100, 12) * $num * 1%;
	$less: 12 * $multiple;

	@return calc(#{$percent} - #{$less}px);
}

@mixin pageWidth() { width: $pageWidth; margin: 0 auto; max-width: calc(100% - #{$gut * 2});
	@include resp(min600) { max-width: calc(100% - #{$gut600 * 2}); }
}

@mixin pageWidthGrid() { width: $pageWidth + $gut; position: relative; left: math.div(-$gut, 2); margin: 0 auto; max-width: calc(100% - #{$gut});
	@include resp(min600) { width: $pageWidth + $gut600; left: -$gut600 / 2; max-width: calc(100% - #{$gut600}); }
}

@mixin headline() { font-family: $headline; line-height: 1em; font-size: 13vw; text-transform: lowercase;
	@include resp(min600) { font-size: 6.5vw; line-height: 1em; }
}

@mixin body() { font-family: $font; font-size: 13px; line-height: 18px; letter-spacing: 0.093em }

.slider { position: relative; display: flex; flex-direction: column; user-select: none; box-sizing: border-box;
	&_inner { overflow: hidden; width: 100%; }
	&_slides { display: flex; 
		&__transition {
			transition: transform 0.25s;
		}
		> * { flex-basis: 0; flex-grow: 1; }
		:global(.u_column) { margin-left: 0; width: 100%; margin-bottom: 0; }
	}
	&_nav { display: flex; flex-flow: row wrap; order: 2; justify-content: center; padding: 10px 0; position: relative; z-index: 1; }
	&_navButton { background: none; border: 0; opacity: 0.3; outline: none; position: relative; transition: opacity 0.25s; padding: 8px; cursor: pointer;
		&::after { content: ''; background: $black; border-radius: 50%; display: block; height: 5px; width: 5px; }
		&__active { opacity: 1;
			&::after { opacity: 1; }
		}
	}
	&_arrow { position: absolute; z-index: 1; background: none; border: none; outline: none; position: absolute; transition: opacity 0.25s; padding: 0px; width: 50%; height: 100%; cursor: pointer;
		&::before { content: ''; display: block; width: 10px; height: 10px; border-top: 1px solid $black; border-right: 1px solid $black; margin: -5px auto 0; position: absolute; }
		&::after { content: ''; position: absolute; top: 50%; width: 20px; height: 1px; background-color: $black; }
		&__left { top: 0; left: 0; margin-left: 20px;
			&::before { transform: rotate(225deg); left: 10px; }
			&::after { left: 9px; }
		}
		&__right { top: 0; right: 0; margin-right: 20px;
			&::before { transform: rotate(45deg); right: 10px; }
			&::after { right: 9px; }
		}
		/*:global(.no-touchevents)*/ 
	}
	@include resp(max600) { align-items: center; padding: 0;
		&_arrow { display: none; }
	}
	&__product { display: inline-flex; vertical-align: top; padding: 0; width: 100%; overflow: hidden;
		.slider {
			&_inner { width: 100%; height: 100%; padding-bottom: 0; }
			&_thumbs { overflow: hidden; position: relative; z-index: 1;
				&_inner { padding: 5px 0; box-sizing: border-box; transform: translateX(0); transition: transform 0.25s; text-align: center;
					&_thumb { width: 45px; margin: 0 10px 10px 0; display: inline-block; vertical-align: top; border: none; border-bottom: 1px solid transparent;
						&:last-child { margin-right: 0; }
						&__selected { border-bottom-color: $black; }
						@include resp(min600) { width: 80px; }
					}
				}
			}
			&_arrow { width: 40%; height: 100%;
				&__left { margin-left: 0; }
				&__right { margin-right: 0; }
			}
		}
	}
}

@include resp(min900) {
	@supports(object-fit: contain) {
		.slider__product { height: calc(100vh - 161px);
			.slider {
				&_slides { height: 100%; }
				&_inner img, &_inner video { height: 100%; object-fit: contain; object-position: center; }
				&_arrow { height: calc(100vh - 161px); }
			}
		}
	}
}
@use "sass:math";

$black: #000000;
$background: #9eb2b1;
$white: #FFFFFF;
$highlight: #01224d; //#E3CC89
$backgroundHighlight: #fee300; // #f5f5f5;
$cta: #01224d;
$sale: #a51915;
$error: #a51915;
$gradientList: #F6CB62, #EFAA5C, #EC9857, #EA8754, #E4664D, #E2544A, #C9354A, #A91C49, #8D1B48, #6F1947, #511746, #361645;

@function gradientColor($pos: 1) {
	@return nth($gradientList, $pos);
}

$font: Helvetica, Helvetica Neue, Arial, sans-serif;
$headline: Anko-SemiBold, Helvetica, Helvetica Neue, Arial, sans-serif;

$totalGridWidthPx: (100 * 12) + (10 * 11);
$gridColumns: 12;
$gut: 10px;
$gut600: 10px;
$col: math.div(100, 12);
$innerWidth: 98%;

$pageWidth: $totalGridWidthPx * 1px;

@mixin resp($media) {
	@if $media == maxProduct {
		@media only screen and (max-width: 396px) { @content; }
	} @else if $media == max600 {
		@media only screen and (max-width: 599px) { @content; }
	} @else if $media == min600 {
		@media only screen and (min-width: 600px) { @content; }
	} @else if $media == navigation {
		@media only screen and (max-height: 625px) and (min-width: 600px) { @content; }
	} @else if $media == span600900 {
		@media only screen and (min-width: 600px) and (max-width: 899px) { @content; }
	} @else if $media == max750 {
		@media only screen and (max-width: 749px) { @content; }
	} @else if $media == min750 {
		@media only screen and (min-width: 750px) { @content; }
	} @else if $media == max900 {
		@media only screen and (max-width: 899px) { @content; }
	} @else if $media == min900 {
		@media only screen and (min-width: 900px) { @content; }
	} @else if $media == min1000 {
		@media only screen and (min-width: 1000px) { @content; }
	} @else if $media == max1200 {
		@media only screen and (max-width: 1159px) { @content; }
	} @else if $media == min1200 {
		@media only screen and (min-width: 1200px) { @content; }
	}
}

@mixin chevronDown($pos: after) { 
	@if $pos == before {
		&:before { content: ""; width: 8px; height: 8px; border-bottom: 1px solid; border-right: 1px solid; transform: rotateZ(45deg); margin: 6px 0 0 10px; display: inline-block; vertical-align: top; }
	} @else {
		&:after { content: ""; width: 8px; height: 8px; border-bottom: 1px solid; border-right: 1px solid; transform: rotateZ(45deg); margin: 6px 0 0 10px; display: inline-block; vertical-align: top; }
	}
}

@mixin grid($total, $col, $gut, $colColor, $gutColor) {
	background-image: repeating-linear-gradient(
		90deg,
		$gutColor,
		$gutColor $gut,
		$colColor $gut,
		$colColor #{math.div(100%, $gridColumns)}
	);
	@include resp(min600) {
		background-image: repeating-linear-gradient(
			90deg,
			$gutColor,
			$gutColor $gut600,
			$colColor $gut600,
			$colColor #{math.div(100%, $gridColumns)}
		);
	}
}

@function strip-unit($num) {
	@return $num / ($num * 0 + 1);
}

@function w($num, $resp: 0, $multiple: 1) {
	$percent: math.div(100, 12) * $num * 1%;
	$less: 12 * $multiple;

	@return calc(#{$percent} - #{$less}px);
}

@mixin pageWidth() { width: $pageWidth; margin: 0 auto; max-width: calc(100% - #{$gut * 2});
	@include resp(min600) { max-width: calc(100% - #{$gut600 * 2}); }
}

@mixin pageWidthGrid() { width: $pageWidth + $gut; position: relative; left: math.div(-$gut, 2); margin: 0 auto; max-width: calc(100% - #{$gut});
	@include resp(min600) { width: $pageWidth + $gut600; left: -$gut600 / 2; max-width: calc(100% - #{$gut600}); }
}

@mixin headline() { font-family: $headline; line-height: 1em; font-size: 13vw; text-transform: lowercase;
	@include resp(min600) { font-size: 6.5vw; line-height: 1em; }
}

@mixin body() { font-family: $font; font-size: 13px; line-height: 18px; letter-spacing: 0.093em }

.text { display: block; 
	&_content { display: block; position: relative; z-index: 2;
		&_headline { margin: 150px 0 50px; 
			@include resp(max600) { text-align: left !important; margin: 100px 0 20px; }
		}
		&_body { width: 700px; max-width: 100%; margin: 30px auto;
			@include resp(min600) { margin: 30px auto; }
			ul { margin-top: 2.5em; padding: 0; }
			li { margin: 1.7em 0; list-style: none; position: relative; padding-left: 40px;
				&:before { content: "—"; position: absolute; left: 5px; top: -1px; }
			}
		}
	}
}
